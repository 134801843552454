@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-color: #fff;
  /* --primary-color: #4c50cd;
  --primary-color-rgb: 76, 80, 205; */
  --primary-color: #e30e81;
  --primary-color-rgb: 227, 14, 129;
  --background-color: #161b22;
  --background-color-rgb: 22, 27, 34;

  --background-color-h: 215;
  --background-color-s: 21%;
  --background-color-l: 11%;

  --background-body-color: #0e1116;
  --shadow-color: #0000001a;
  --gray-color: #828890;
  --gray-color-rgb: 130, 136, 144;
}

html,
body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background-color: var(--foreground-color);
  scrollbar-width: none;
  max-width: 100rem;
  margin-inline: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  @apply dark:bg-background-body-color;
}

@layer base {
  h1 {
    @apply text-5xl md:text-5xl lg:text-6xl xl:text-7xl;
    @apply font-bold;
  }

  h2 {
    @apply text-4xl md:text-4xl lg:text-5xl xl:text-6xl;
    @apply font-semibold;
  }

  h3 {
    @apply text-3xl md:text-3xl lg:text-4xl xl:text-5xl;
    @apply font-semibold;
  }

  h4 {
    @apply text-2xl md:text-2xl lg:text-3xl xl:text-4xl;
    @apply font-semibold;
  }

  h5 {
    @apply text-xl md:text-xl lg:text-2xl xl:text-3xl;
    @apply font-semibold;
  }

  h6 {
    @apply text-lg md:text-lg lg:text-xl xl:text-2xl;
    @apply font-semibold;
  }

  p {
    @apply text-base md:text-lg;
    @apply font-light;
  }
}

/* pricing card */
.shadow-full {
  -webkit-box-shadow: 0px 2px 12px var(--shadow-color);
  -moz-box-shadow: 0px 2px 12px var(--shadow-color);
  -o-box-shadow: 0px 2px 12px var(--shadow-color);
  box-shadow: 0px 2px 12px var(--shadow-color);
}

.HeroPattternImage {
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
    linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  mask-size: 100% 50%;
  mask-repeat: no-repeat;
  mask-position: top, bottom;
}
@screen md {
  .HeroPattternImage {
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
      linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }
}

/* link underline animation */
.underlined-link a {
  display: block;
  position: relative;
}

.underlined-link a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.1rem;
  margin: 0.1rem 0;
  background-color: white;
  mix-blend-mode: difference;
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}

.underlined-link a:hover::after,
.underlined-link a:focus::after {
  opacity: 1;
  transform: translate3d(0, 0.1em, 0);
}
